export const validarForms = (valor, campo) => {
    if (campo === 'genero') {
        if (valor?.toLowerCase() === 'femenino' || valor === 'F') {
            return 'F'
        } else if (valor?.toLowerCase() === 'masculino' || valor === 'M') {
            return 'M'
        } else if (valor?.toLowerCase() === 'otro' || valor === 'OTRO') {
            return 'OTRO'
        } else {
            return ""
        }
    }
}

export const titleCase = (str) => {
    return str
        .toLowerCase()
        .split(' ')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
};