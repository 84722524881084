import React from 'react'
import styled from 'styled-components'

function Modal({ children,
	estado,
	cambiarEstado,
	titulo = 'Alerta',
	mostrarTitulo = true,
	oscurecerFondo = true,
	mensaje
}) {
	return (
		<>
			{estado &&
				<Overlay oscurecerFondo={oscurecerFondo} >
					<ContenedorModal>
						{mostrarTitulo &&
							<TituloModal>
								<h3>{titulo}</h3>
							</TituloModal>
						}
						<BotonCerrar onClick={() => cambiarEstado(false)}>X</BotonCerrar>
						<Contenido>
							<p>{mensaje}</p>
							<BotonAceptar onClick={() => cambiarEstado(false)}>Aceptar</BotonAceptar>
						</Contenido>
					</ContenedorModal>
				</Overlay >
			}
		</>
	)
}

export default Modal

const Overlay = styled.div`
	width: 100vw;
	height: 100vh;
	position: fixed;
	top: 0;
	left: 0;
	background: ${props => props.oscurecerFondo ? 'rgba(0,0,0,0.5)' : ''} ;
	padding: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
`;

const ContenedorModal = styled.div`
	width: 500px;
	min-height: 100px;
	background: #fff;
	position: relative;
	border-radius: 5px;
	box-shadow: rgba(100,100,111,0.2) 0px 7px 29px 0px;
	padding: 20px;
`;

const TituloModal = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 20px;
	padding-bottom: 20px;
	border-bottom: 1px solid #E8E8E8;

	h3 {
		font-weight: 500;
		font-size: 16px;
		color: #1766DC;
	}
`;

const BotonCerrar = styled.button`
	position: absolute;
	top: 20px;
	right: 20px;

	width: 30px;
	height: 30px;
	border: none;
	background: none;
	cursor: pointer;
	transition: .3s ease all;
	border-radius: 5px;
	color:#1766DC ;

	&:hover {
		background: #f2f2f2;
	}
`;

const Contenido = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;

	p {
		font-size: 18px;
		margin-bottom: 20px;
	}
`;

const BotonAceptar = styled.button`
	display: block;
	padding: 10px 30px;
	border-radius: 100px;
	color: #fff;
	border: none;
	background: #1766DC;
	cursor: pointer;
	font-family: 'Roboto', sans-serif;
	font-weight: 500;
	transition: .3s ease all;
	justify-content: center;

	&:hover {
		background: #0066FF;
	}
`;