function verificarObjeto(objeto) {
    for (let propiedad in objeto) {
        if (objeto.hasOwnProperty(propiedad)) {
            if (objeto[propiedad].trim() !== '') {
                return false;
            }
        }
    }
    return true;
}
export default verificarObjeto