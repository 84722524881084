import { toast } from 'react-toastify'
const mostrarErrores = (verificaciones) => {

    for (const campo in verificaciones) {
        if (verificaciones[campo].trim() !== "") {
            // El campo no está vacío, mostrar mensaje de error
            toast.error(verificaciones[campo], {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    }
}

export default mostrarErrores